import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "150px",
  height: "150px",
  marginTop: "30px",
  marginBottom: "4vh",
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(120, 25%, 80%, 0.3)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(120, 25%, 80%, 0.5)",
  backgroundImage: `url(${"headshot.jpg"})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.up("sm")]: {
    height: 150,
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(120, 100%, 25%, 0.2)",
    backgroundImage: `url(${"headshot.jpg"})`,
    outlineColor: "hsla(120, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundOverflow: "visible",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 18 },
          pb: { xs: 5, sm: 0 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{
            alignItems: "center",
            width: { xs: "100%", sm: "70%" },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(3rem, 10vw, 3.5rem)",
            }}
          >
            Welcome to my&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "#6bd46a",
                ...theme.applyStyles("dark", {
                  color: "primary.light",
                }),
              })}
            >
              Website
            </Typography>
          </Typography>
          <Typography
            variant="h3"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: { xs: "1.2rem", sm: "clamp(1.5rem, 3vw, 1.5rem)" },
              fontWeight: "bold",
              mt: { xs: 2, sm: 2 },
              mb: { xs: 2, sm: 4 },
              position: "relative",
            }}
          >
            I am an&nbsp;
            <Typography
              component="span"
              variant="h3"
              sx={{
                fontSize: "inherit",
                fontWeight: "inherit",
                position: "relative",
              }}
            >
              Organisational Psychologist&nbsp;
              <Box
                sx={{
                  display: { xs: "none", sm: "block" },
                }}
              >
                <img
                  src="angry-underline1.svg"
                  alt="Data Analytics Image"
                  style={{
                    position: "absolute",
                    top: "250%",
                    left: "10%",
                    transform: "translateY(-50%) rotate(4deg)",
                    width: "100%",
                    height: "auto",
                    zIndex: -1,
                  }}
                />
              </Box>
            </Typography>
            passionate about&nbsp;
            <Typography
              component="span"
              variant="h3"
              sx={{
                fontSize: "inherit",
                fontWeight: "inherit",
                position: "relative",
              }}
            >
              Data Analytics
              <Box
                sx={{
                  display: { xs: "none", sm: "block" },
                }}
              >
                <img
                  src="rb_177493.png"
                  alt="Data Analytics Image"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "-38%",
                    transform: "translateY(-50%)",
                    width: "300px",
                    height: "auto",
                    zIndex: -10,
                  }}
                />
              </Box>
            </Typography>
          </Typography>

          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              width: { sm: "100%", md: "80%" },
              mt: 2,
            }}
          >
            Here I display my relevant work experience, personal and data
            projects, and test out my web development skills. Please feel free
            to reach out and connect with me on LinkedIn, link is in the menu
            bar.
          </Typography>
        </Stack>
        <StyledBox id="image"></StyledBox>
      </Container>
    </Box>
  );
}

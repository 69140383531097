import React from "react";
import { useState } from "react";
import AppAppBar from "./AppAppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getMPTheme from "../theme/getMPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Footer from "./Footer";
import Test from "./BlogCard.jsx";
import BlogCardHero from "./BlogCardHero.jsx";
import Divider from "@mui/material/Divider";
import { Opacity } from "@mui/icons-material";

const BlogPage = () => {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <CssBaseline /> {/* Normalize styles */}
        <Box style={pageStyle}>
          <Container style={{ display: "flex" }}>
            <AppAppBar />
          </Container>
          <Box
            sx={{
              // display: { xs: "none", sm: "flex" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f2f2f2",
              width: "100%",
              height: { xs: "", sm: "350px" },
              mt: { xs: 19, sm: 23 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { sm: "90%", lg: "75%" },
              }}
            >
              <BlogCardHero
                image="teamwork.jpeg"
                title="High Performing Teams"
                description="The continual pursuit of refinement and efficiency has led many organisations to heavily integrate teams into day-to-day operations."
                link="/high-performing-teams"
                date="19 November 2024"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: "50px",
            maxWidth: { xs: "90%", sm: "75%" },
            margin: "0 auto",
            marginTop: "5vh",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(3, 1fr)",
            },
            mt: { xs: 7, sm: 7 },
          }}
        >
          <Test
            image="teamwork.jpeg"
            title="High Performing Teams"
            description="The continual pursuit of refinement and efficiency has led
                  many organisations to heavily integrate teams into day-to-day
                  operations. Instability within"
            orderIndex="1"
            link="/high-performing-teams"
          ></Test>
          <Test
            image="People_analytics.jpeg"
            title="People Analytics"
            description="Harnessing People Analytics Through an Organisational
                  Psychology Lens. In an increasingly data-driven world, people analytics has
                quickly become an essential"
            orderIndex="2"
            link="/people-analytics"
          ></Test>
        </Box>
        <Box sx={{ marginTop: "10vh" }}>
          <Divider></Divider>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default BlogPage;

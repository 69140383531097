import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { display, useTheme } from "@mui/system";
import { motion } from "framer-motion"; // Import motion
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { filter } from "framer-motion/client";
import { InvertColors } from "@mui/icons-material";

const whiteLogos = [
  "logo 2024 Final v2-06.svg",
  "ANZ-Logo-2009.svg",
  "Monash.svg",
  "RCH.png",
  "dentaview.png",
];

const darkLogos = [
  "logo 2024 Final v2-06.svg",
  "ANZ-Logo-2009.svg",
  "Monash.svg",
  "RCH.png",
  "dentaview.png",
];

const logoStyle = {
  height: "50px",
  margin: "0 32px",
  filter: "saturate(75%) contrast(110%)",
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === "light" ? darkLogos : whiteLogos;

  return (
    <>
      <Box
        id="logoCollection"
        sx={{
          height: {
            xs: theme.Box,
            sm: `calc(100vh - (613.27px + 52px))`,
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            height: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginBottom: { xs: "8vh", sm: "1.5vh" },
          }}
        >
          <Typography
            component="p"
            variant="h6"
            align="center"
            sx={{
              color: "text.secondary",
              fontWeight: "500",
              marginBottom: "1vh",
            }}
          >
            Experience at these companies
          </Typography>
          <Grid
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              mt: { xs: 0, sm: 2 },
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            {logos.map((logo, index) => (
              <Grid item key={index} mt={{ xs: 2, sm: 0 }}>
                <img
                  src={logo}
                  alt={`Employer ${index + 1}`}
                  style={logoStyle}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            height: "50%",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginBottom: "1.5vh",
          }}
        >
          <motion.div
            initial={{ y: 0 }}
            animate={{
              y: [0, -10, 0],
              transition: {
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut",
              },
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2vh",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <KeyboardDoubleArrowDownIcon fontSize="large" />
          </motion.div>
        </Box>
      </Box>
    </>
  );
}

import React from "react";
import { useState } from "react";
import AppAppBar from "../AppAppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getMPTheme from "../../theme/getMPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Footer from "../Footer";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";

const High_performing_teams = () => {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    color: "#333",
    margin: 0,
    padding: 0,
  };

  const headerStyle = {
    backgroundColor: "#f5f5f5",
    padding: "20px",
    textAlign: "center",
  };

  const sectionStyle = {
    padding: "20px",
    margin: "20px",
    borderBottom: "1px solid #ddd",
  };

  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "20px",
    margin: "20px",
  };

  const gridBoxStyle = {
    backgroundColor: "#f2f2f2",
    padding: "20px",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "left",
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
    borderRadius: "8px",
    marginTop: "10px",
  };

  const headingStyle = {
    fontSize: "2.5em",
    color: "#333",
  };

  const subheadingStyle = {
    fontSize: "1.8em",
    marginTop: 0,
  };

  const textStyle = {
    fontSize: "1em",
    lineHeight: "1.6",
  };

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <Box style={pageStyle}>
          <CssBaseline /> {/* Normalize styles */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              backgroundImage: `url(${"teamwork.jpeg"})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Container
              style={{
                display: "flex",
                marginBottom: "40vh",
              }}
            >
              <AppAppBar />
            </Container>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { xs: "90%", sm: "59%" },
              }}
            >
              <div className="App">
                <header>
                  <h1>Developing High Performing Teams</h1>
                </header>
                <section
                  style={{
                    backgroundColor: "#f2f2f2",
                    display: "grid",
                    gap: "0px",
                    alignContent: "center",
                    borderRadius: "8px",
                  }}
                >
                  <h2
                    style={{
                      width: "100%",
                      paddingLeft: "3vh",
                      paddingBottom: "0px",
                    }}
                  >
                    Key Takeaways
                  </h2>
                  <ul>
                    <li>
                      Use task needs analysis to identify KSAs when adjusting
                      team inputs.
                    </li>
                    <li>
                      Employ team development interventions targeting mediating
                      mechanisms when facing structural limitations.
                    </li>
                    <li>
                      Team Building enhances social relations and role clarity.
                    </li>
                    <li>
                      Leadership development improves team effectiveness and
                      outcomes by 29%.
                    </li>
                    <li>
                      Team development interventions targeting mediating
                      mechanisms offer the best ROI for organisations.
                    </li>
                  </ul>
                </section>
                <section>
                  <h2>Introduction</h2>
                  <p>
                    The continual pursuit of refinement and efficiency has led
                    many organisations to heavily integrate teams into
                    day-to-day operations. Instability within the macro economy,
                    increased domestic and international competition are all
                    factors that have led organisations to be agile and protect
                    themselves from the impending complexities of modern-day
                    business. Beyond serving as a safeguard against risks, teams
                    provide organisations with increased efficiencies that
                    translate into performance gains and ultimately increased
                    profitability. Specifically, understanding teams and
                    cultivating high-performing teams can allow organisations to
                    better utilise pre-existing human capital to generate
                    performance gains. This report will examine the factors that
                    influence teams as well as evidence-based best practices
                    that can facilitate high-performing teams within an
                    organisational setting.
                  </p>
                </section>

                <section>
                  <h2>Defining Teams</h2>
                  <p>
                    A team, within an organisational context, is two or more
                    individuals who socially interact (in-person or virtually)
                    with each other. These individuals share one or more common
                    goals and are brought together to perform and complete tasks
                    that align with organisational objectives. The individuals
                    are interconnected in terms of workflow, goals, and
                    outcomes, meaning that each member's contribution affects
                    the overall team outcome. Additionally, each team member has
                    different roles and responsibilities that contribute to
                    achieving the team goal. Finally, the team is a functional
                    element that is part of a broader organisational system,
                    with similarities and differences from the general
                    organisational objectives.
                  </p>
                </section>

                <section>
                  <h2>Importance of High-Performing Teams</h2>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                      },
                      gap: {
                        xs: "0px",
                        sm: "20px",
                      },
                    }}
                  >
                    <p>
                      High-performing teams can provide a significant
                      competitive advantage for organisations in areas such as
                      productivity, profitability, and customer satisfaction. As
                      the prevalence of team-based organisations rises, the
                      underutilisation of teams, specifically high-performing
                      teams, will result in a drop in and sacrifice of
                      competitive advantage. Industry exploration suggests that
                      organisations with high-performance teams are almost two
                      times more likely to be financially successful than
                      organisations in the same market area.
                    </p>
                    <Typography
                      sx={{
                        backgroundColor: "#f2f2f2",
                        display: "grid",
                        alignContent: "center",
                        textAlign: "center",
                        borderRadius: "8px",
                        padding: 4,
                      }}
                    >
                      <strong>
                        “Coming together is a beginning. Keeping together is
                        progress. Working together is success.”
                      </strong>
                      – Henry Ford
                    </Typography>
                  </Box>
                </section>

                <section>
                  <h2>Understanding Teams</h2>
                  <p>
                    Along with having a prevailing role in organisations, teams
                    are an integral part of wider society and have garnered
                    significant attention in research. From this literature,
                    teams and their subsequent performance are conceptualized as
                    a multi-level process consisting of team-level taskwork,
                    individual-level taskwork, and team member interactions. A
                    leading framework within the team’s literature is the
                    Input-Mediators-Output (IMO) framework. Furthermore, the IMO
                    framework provides a broad foundational understanding of
                    teams that is necessary for later parts of this report that
                    explore how to develop high-performing teams.
                  </p>
                </section>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src="IMO_model.png"
                    style={{
                      width: "80%",
                      marginTop: "4vh",
                      marginBottom: "4vh",
                    }}
                  ></img>
                </div>

                <section>
                  <h3>Inputs</h3>
                  <p>
                    In this framework, Inputs refer to predisposed factors that
                    facilitate or limit the efficacy of taskwork. Example inputs
                    include individual team member traits (knowledge, skills,
                    attitudes and personality), task characteristics, team
                    design (composition and size), and organisational context.
                    Overall, inputs refer to relatively tangible aspects of
                    groups and are important elements to factor in when forming
                    a team.
                  </p>

                  <h3>Mediators</h3>
                  <p>
                    Mediating mechanisms describe how team outcomes are derived
                    from the set of inputs; in essence, how team members
                    interact to accomplish the task. Marks and colleagues found
                    that whilst behavioural processes were a part of linking
                    inputs with outcomes, the team’s attitudes and cognitions
                    were also an important element. Therefore, mediating
                    mechanisms are often referred to as the ABC’s (Attitudes,
                    Behaviours, and Cognitions).
                  </p>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(3, 1fr)",
                      },
                      gap: "20px",
                      margin: "20px",
                    }}
                  >
                    <div style={gridBoxStyle}>
                      <p style={textStyle}>
                        <strong>Attitudes</strong> are an internal state that
                        impacts and dictates the interaction between team
                        members. Attitudes include mutual trust, team cohesion,
                        psychological safety<sup>5</sup> and collective efficacy
                        <sup>1</sup>.
                      </p>
                    </div>

                    <div style={gridBoxStyle}>
                      <p style={textStyle}>
                        <strong>Behaviours</strong> are the processes and skills
                        required to accomplish teamwork. This includes team
                        leadership, information sharing, conflict resolution and
                        backup behaviour<sup>2</sup>.
                      </p>
                    </div>

                    <div style={gridBoxStyle}>
                      <p style={textStyle}>
                        <strong>Cognitions</strong> are collective experiences
                        and knowledge that provide insight to facilitate
                        effective teamwork. Cognitions include shared mental
                        models
                        <sup>3</sup>, situational awareness and transactive
                        memory systems<sup>4</sup>.
                      </p>
                    </div>
                  </Box>
                  <div style={{ opacity: 0.55 }}>
                    <ol>
                      <li>
                        Collective efficacy is the group’s belief that they can
                        excel in performing the task.
                      </li>
                      <li>
                        Backup behaviour is team members supporting other
                        members to perform their roles.
                      </li>
                      <li>
                        Shared mental models are a collective idea of the team
                        norms and goals for the task.
                      </li>
                      <li>
                        Transactive memory systems are the allocation of
                        different information and knowledge to set individuals
                        in the team, but all members are aware of who knows what
                        information.
                      </li>
                      <li>
                        Psychological safety is an environment where team
                        members feel safe to express their ideas, ask questions,
                        raise concerns, or make mistakes without fear of
                        punishment or humiliation
                      </li>
                    </ol>
                  </div>

                  <h3>Outcomes</h3>
                  <p>
                    Outcomes are largely defined as the accomplishment of
                    individual, team, or organisational goals. In general, teams
                    are utilised to increase organisational efficiency; however,
                    it is also important to understand and measure individual
                    experiences as this will inform their preference towards
                    teamwork moving forward. Goal accomplishment measurement
                    includes:
                  </p>
                  <ul>
                    <li>
                      Evaluating and quantifying the outputs produced by teams,
                      with particular focus on metrics that encapsulate
                      efficiency, innovation and productivity for that context.
                      For example, in a sales team this may be sales/profit
                      quotas, pre-determined KPI goals, client attraction and
                      retention.
                    </li>
                    <li>
                      Measuring team member attitudes, specifically trust, team
                      efficacy, wellbeing, and satisfaction.
                    </li>
                    <li>
                      Assessing behaviours such as turnover and absenteeism.
                    </li>
                  </ul>
                </section>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src="Picture 1.png"
                    style={{
                      width: "40%",
                      borderRadius: "10px",
                      marginTop: "4vh",
                      marginBottom: "4vh",
                    }}
                  ></img>
                </div>

                <section>
                  <h2>Effective Teams</h2>
                  <p>
                    Continuing on from understanding teams, it then becomes
                    important to understand what elements have been associated
                    with effective teams. Understanding these key areas will
                    create the foundation for developing high performing teams
                    and synthesising the best practices for team interventions.
                    Derived from the literature, the following are key
                    scientific findings linking team elements with highly
                    effective teams:
                  </p>
                  <ul>
                    <li>
                      <strong>Team knowledge, skills, and attitudes:</strong>
                      Cannon-Bowers and colleagues2 found that team knowledge,
                      skills and attitudes are required competencies to ensure
                      effective teamwork.
                    </li>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(3, 1fr)",
                        },
                        gap: "20px",
                        margin: "20px",
                      }}
                    >
                      <div style={gridBoxStyle}>
                        <p style={textStyle}>
                          Team <strong>knowledge</strong> is an understanding of
                          the necessary information for effective performance,
                          such as member expertise and clarity of team member
                          roles.
                        </p>
                      </div>

                      <div style={gridBoxStyle}>
                        <p style={textStyle}>
                          Team <strong>skills</strong> are observable
                          capabilities required to accomplish team tasks and
                          teamwork. Examples include communication style and
                          adaptableness.
                        </p>
                      </div>

                      <div style={gridBoxStyle}>
                        <p style={textStyle}>
                          Team <strong>attitudes</strong> are the underlying
                          perceptions and emotions surrounding the teamwork.
                          This includes mutual trust and preference for working
                          within a team.
                        </p>
                      </div>
                    </Box>
                    <li>
                      <strong>Team task analysis: </strong> A needs analysis is
                      conducted to identify the essential teamwork behaviours
                      and tasks to be performed. Subsequently, then identify the
                      requisite knowledge, skills, and abilities necessary to
                      complete these tasks and teamwork behaviours<sup>2</sup>.
                    </li>
                    <li>
                      <strong>Team selection methods: </strong> Select team
                      members and leaders based on knowledge, skills and
                      attitudes that are aligned to the demands of the task
                      work. Team selection can also be beneficial by ensuring a
                      diverse representation of skills and personalities.
                      Utilising skill assessments, personality tests and job
                      simulation can assist in this process<sup>8</sup>.
                    </li>
                    <li>
                      <strong>Team leader development: </strong> Team leader
                      capabilities are developed in order to positively impact
                      overall team functioning<sup>15</sup>.
                    </li>
                    <li>
                      <strong>Performance measurement system: </strong> Systems
                      are used to assess individual and team processes as well
                      as evaluate and diagnose team performance. The system
                      should include multiple measures such as team leader
                      reports and performance simulations with group reviews
                      <sup>17</sup>.
                    </li>
                    <li>
                      <strong>Diagnostic feedback: </strong> This is feedback
                      that evaluates team processes and performance, identifying
                      crucial mistakes and acknowledging achievements. It is
                      typically given soon after a performance period, enabling
                      teams to adjust their behaviour for the next period
                      <sup>19</sup>.
                    </li>
                  </ul>
                </section>

                <section>
                  <h2>Best Practices</h2>
                  <p>
                    As mentioned previously, teams are complex multi-level
                    entities, thus, when developing high performing teams, it is
                    paramount to identify the most pertinent approach based on
                    the context. When forming high performance teams, it is
                    possible to influence at the input levels, such as a team
                    composition and member traits. Alternatively, in order to
                    develop high performance within pre-established teams,
                    targeting mediating mechanisms can be more effective.
                    Furthermore, due to organisational structures, task rigidity
                    and human capital limitations, maximising team performance
                    through mediating mechanisms may be the best choice for many
                    organisations.
                  </p>
                </section>

                <section>
                  <h2>
                    Developing High Performance: Mediating Mechanism Focused
                  </h2>
                  <p>
                    Firstly, it is important to establish that these approaches
                    can apply to both established and forming teams. A
                    systematic approach that aims to improve team performance is
                    often referred to as a team development intervention (TDI).
                    Aligned with the findings of Cannon- Bowers and colleagues,
                    TDI’s focus on equipping teams with KSA competencies to
                    positively influence team performance. In the following
                    sections we will assess and evaluate two common forms of
                    TDI’s, team building and leadership training. Moreover,
                    these TDI’s are most effective as they do not rely on the
                    context but are rather informed by the context. This means
                    that the efficacy of these TDI’s in not conditional on
                    restructuring the team or altering the task design, but
                    rather providing teams with efficient processes to navigate
                    a range of varying contexts.
                  </p>

                  <h3>Team Building</h3>
                  <p>
                    Team building is a series of interventions aimed at
                    improving social interactions and interpersonal relations.
                    These interventions include:
                  </p>
                  <ul>
                    <li>
                      Goal setting: All team members actively participate in
                      identifying individual and team goals as well as planning
                      how to achieve of these goals.
                    </li>
                    <li>
                      Interpersonal relationship management: Increases
                      productive teamwork processes such as frequent
                      communication, mutual trust and positive attitudes towards
                      the team.
                    </li>
                    <li>
                      Role clarification: Increases communication regarding team
                      member roles and responsibilities which can facilitate
                      backup behaviours.
                    </li>
                    <li>
                      Problem-solving: Promotes team cooperation when
                      problem-solving task-related issues and making complex
                      decisions regarding tasks<sup>18</sup>.
                    </li>
                  </ul>
                  <p>
                    After analysing the literature, Klien and colleagues12 found
                    that role clarification and team goal setting were the most
                    effective interventions for team performance gains. Both of
                    these elements are directly working to improve the teams
                    shared mental models, which is an element of the team
                    cognitions in the IMO model.
                  </p>
                  <p>
                    Team building is an effective TDI because it equips teams
                    with KSAs that are not limited to specific tasks. This leads
                    to two main benefits for the organisation’s return on
                    investment (ROI). Firstly, team building helps develop
                    interpersonal relationship management and problem-solving
                    skills that can be applied beyond the current task.
                    Secondly, all KSAs acquired by each team member increase the
                    organisation’s human capital and serve utility beyond the
                    specific task and team. In short, team building TDIs offer
                    both short-term and long-term benefits to organisations.
                  </p>

                  <h3>Leadership Development</h3>
                  <p>
                    In line with findings that leader development is associated
                    with team effectiveness15, leadership training TDI’s are a
                    supported way to develop high performing teams. Recent
                    evaluation of leadership development TDIs, revealed it
                    improves learning and organisational outcomes by up-to 29%
                    <sup>14</sup>.
                  </p>
                  <p>
                    Leadership development provides training around a series of
                    leadership competencies, such as but not limited to14:
                  </p>
                  <ul>
                    <li>
                      Resolve team conflict in order to facilitate mutual trust
                      and cohesion
                    </li>
                    <li>Provide feedback in a way that reflects group norms</li>
                    <li>Change management</li>
                  </ul>
                  <p>
                    These competencies are typically developed in two stages.
                    The first stage is a acquiring the foundational knowledge in
                    online modules. Participants then attend in-person training
                    for the second stage. Throughout this process they will
                    engage with role-play situations designed to test their
                    newly acquired KSA’s, with accompanying feedback designed to
                    further enhance these. Additionally, feedback is
                    collaborative and focuses on reflection about individual
                    leadership practices and developing action plans to apply
                    the new KSAs in a real-world work context.
                  </p>
                  <p>
                    In addition to acquiring new KSAs, leadership training also
                    includes learning how to facilitate psychological safety
                    within teams. This involves creating an environment where
                    team members feel safe to express their ideas, ask
                    questions, raise concerns, or make mistakes without fear of
                    punishment or humiliation. In work team context, this is a
                    shared expectation held by all team members that they will
                    not be rejected, embarrassed, or punished for taking risks,
                    sharing ideas, or seeking feedback in the workplace.
                  </p>
                </section>
              </div>
            </Box>
          </Box>
          <Divider></Divider>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default High_performing_teams;

import React from "react";
import { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getMPTheme from "../theme/getMPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const BlogCardHero = ({
  image,
  title,
  description,
  orderIndex,
  link,
  date,
}) => {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const navigate = useNavigate();

  function truncateTextAtSpace(text, maxLength) {
    if (text.length <= maxLength) return text;
    const truncated = text.slice(0, maxLength);
    return truncated.slice(0, truncated.lastIndexOf(" ")) + "...";
  }

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            margin: "20px auto",
            paddingTop: { xs: "0px", sm: "20px" },
            paddingBottom: "20px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              marginRight: { xs: "", sm: "20px" },
              marginBottom: { xs: "20px", sm: "" },
              width: { xs: "90%", sm: "" },
            }}
          >
            <h2
              style={{ fontSize: "32px", marginBottom: "10px", color: "#333" }}
            >
              {title}
            </h2>
            <Typography
              sx={{
                fontStyle: "p",
                fontSize: { xs: "14px", sm: "18px" },
                color: "rgb(102,102,102)",
                lineHeight: "1.6",
                marginBottom: "20px",
              }}
            >
              {description}
            </Typography>
            <button
              onClick={() => navigate(link)}
              style={{
                padding: "10px 20px",
                fontSize: "14px",
                fontWeight: "bold",
                color: "#fff",
                backgroundColor: "hsl(120, 70%, 70%)",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              READ POST
            </button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <img
                src="headshot.jpg"
                alt="Author"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  marginRight: "15px",
                  objectFit: "cover",
                }}
              />
              <div style={{ fontSize: "14px", color: "#666" }}>
                <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                  Luke Caroll
                </span>
                • {date}
              </div>
            </div>
          </Box>

          {/* Right Section: Image */}
          <Box sx={{ display: { xs: "none", sm: "flex" }, flexShrink: 0 }}>
            <img
              src={image}
              alt={title}
              style={{
                width: "500px",
                height: "300px",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          </Box>
          <Box sx={{ display: { xs: "", sm: "none" }, flexShrink: 0 }}>
            <img
              src={image}
              alt={title}
              style={{
                width: "90vw",
                height: "55vw",
                borderRadius: "4px",
                objectFit: "cover",
              }}
            />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default BlogCardHero;

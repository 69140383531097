import React, { useEffect, useState } from "react";
import AppAppBar from "../AppAppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getMPTheme from "../../theme/getMPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Footer from "../Footer";
import Divider from "@mui/material/Divider";

const EmployeeSalary = () => {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const [htmlContent, setHtmlContent] = useState(""); // State to store HTML content
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  useEffect(() => {
    // Fetch the HTML content
    fetch("/EmployeeSalary.html")
      .then((response) => response.text())
      .then((html) => setHtmlContent(html))
      .catch((error) => console.error("Error fetching HTML file:", error));
  }, []);

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <Box style={pageStyle}>
          <CssBaseline />
          <Container style={{ display: "flex" }}>
            <AppAppBar />
          </Container>
          <Box
            sx={{
              width: "95%",
              justifyContent: "center",
              mt: 20,
            }}
          >
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "2vh",
              }}
            >
              Employee Salary Machine Learning Model
            </h1>
            {/* Render the HTML content directly */}
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Box>
          <Divider />
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default EmployeeSalary;

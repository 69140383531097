import React from "react";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";

const Test = ({ image, title, description, orderIndex, link, Opacity = 1 }) => {
  function truncateTextAtSpace(text, maxLength) {
    if (text.length <= maxLength) return text;
    const truncated = text.slice(0, maxLength);
    return truncated.slice(0, truncated.lastIndexOf(" ")) + "...";
  }

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // maxWidth: "300px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "#fff",
    opacity: Opacity,
  };

  const imageStyle = {
    width: "100%",
    height: "50%", // Fixed height for the image
    objectFit: "cover",
  };

  const contentStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    textAlign: "left",
    flexGrow: 1,
  };

  const titleStyle = {
    fontSize: "18px",
    fontWeight: "bold",
    margin: "0 0 8px",
    color: "#333",
  };

  const descriptionStyle = {
    fontSize: "14px",
    color: "#666",
    margin: "0 0 16px",
  };

  const buttonStyle = {
    display: "inline-block",
    padding: "10px 20px",
    fontSize: "14px",
    fontWeight: "bold",
    width: "40%",
    color: "#fff",
    backgroundColor: "hsl(120, 70%, 70%)",
    border: "none",
    borderRadius: "4px",
    textDecoration: "none",
    cursor: "pointer",
    textAlign: "center",
    marginTop: "auto",
  };

  return (
    <div style={cardStyle}>
      <img style={imageStyle} src={image} alt={title} />
      <div style={contentStyle}>
        <h2 style={titleStyle}>{title}</h2>
        <p style={descriptionStyle}>{truncateTextAtSpace(description, 119)}</p>
        <a href={link} style={buttonStyle}>
          READ POST
        </a>
      </div>
    </div>
  );
};

export default Test;
